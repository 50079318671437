@import "../variables.scss";

.main-header,
.allianceairNavbar .dropdown-menu {
  // background: url("/Images/navbar.png");
  // background: rgb(255,255,255);
  // background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,204,153,1) 100%);
  background-size: 500px;
}

.dropdown-menu {
  border: whitesmoke;
}

.dropdown-item {

  &:hover,
  &:focus {
    color: map-get($colorPallete, theme) !important;
  }
}

.navbar {
  padding: 0px 0px !important;
}

.allianceairNavbar {
  button {
    background: transparent !important;
    border: none;
    margin: 0px;
    padding: 0px;
    color: map-get($header, navFontColor) !important;
    font-weight: 600;

    &:focus,
    &:hover {
      box-shadow: none !important;
      border: none !important;
      background: transparent !important;
      color: map-get($header, navFontColor) !important;
      font-weight: 600;
    }
  }
}

.allianceairVerticalNavbar {
  margin: 14px 0px;
  justify-content: flex-end;
  font-size: medium !important;

  .btn {
    font-size: medium !important;
  }

  .dropdown-item {
    font-size: medium !important;
  }
}

.nav-link,
button {
  font-size: large;
  color: map-get($header, navFontColor) !important;

  &.active,
  &:hover {
    background: map-get($header, navHoverBackground) !important;
    color: map-get($header, navHoverFontColor) !important;
  }
}

.allianceairLoginNav {
  .nav-link {
    background: map-get($header, loginBackground) !important;
    color: map-get($header, loginColor) !important;
    text-decoration: none;
  }

  background: map-get($header, loginBackground) !important;
  color: map-get($header, loginColor) !important;
  text-decoration: none;

  a,
  &:hover {
    color: map-get($header, loginColor) !important;
    text-decoration: none;
  }
}


.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: visibility 0s linear 200ms, opacity 200ms;
}

.m-fadeIn {
  visibility: visible;
  opacity: 1;
  position: static;
  transition: visibility 200ms linear 0s, opacity 200ms;
}

.allianceairNavBarDropDown {
  border-radius: 5px;
  border: 1px white solid;
  padding: 10px;
  // background-image: url("/Images/navbar.png");
  background-color: #ffcc99;
  position: absolute;
  top: 40px, ;
  left: 60%;
  right: 0;
  z-index: 100;
  text-align: center;
  display: block;
  height: auto;
  transition-property: height;
  visibility: visible;
  max-height: auto;
}

.allianceairNavBarDropDownactive,
.allianceairNavBarDropDowninactive {
  left: 0;
  right: 0;
  top: 60px;
  padding: 10px;

  ul {
    margin: 0px;
    padding: 0px;

    li {
      padding: 10px 0px;
    }

    a {
      color: map-get($colorPallete, theme);
      text-decoration: none;
      display: block;
      font-weight: 100;

      &:hover {
        background-color: white;
        color: map-get($colorPallete, theme);
        text-decoration: none;
      }
    }
  }
}

.allianceairNavBarDropDowninactive {
  transition: visibility 300ms ease-out, opacity 300ms;
  visibility: hidden;
  opacity: 0;
  top: 0px;
  height: 0px;
}

.hidden
{
  display: none;
}

.allianceairNavBarDropDownactive {
  transition: visibility 300ms ease-in, opacity 300ms;
  visibility: visible;
  opacity: 1;
}

.allianceairNavbar {
  width: 100%;
  align-items: center;
  padding: 0;

  .horizontalNavbar {
    ul {
      width: 100%;
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
      margin: -8px;

      li {
        padding: 20px;

        &:hover {
          // border-bottom: 1px solid white;
        }

        a {
          color: map-get($colorPallete, themeSecondary);
          text-decoration: none;
          line-height: 20px;
          font-size: large;
          svg {
            color: map-get($colorPallete, theme);
          }

          &:hover {
            text-decoration: none;
            color: map-get($colorPallete, theme);
          }
        }
      }
    }
  }
}