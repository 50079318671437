@import "../../variables.scss";

pre {
    font-size: map-get($contact, bodyFontSize) !important;
}

.h5 {
    font-size: map-get($contact, headerFontSize) !important;
}

.contactbar .card-body {
    padding: 0 !important;
}

.contactbar {
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.contactus.card {
    background-color: map-get($colorPallete, themeInBetween) !important;
}