@import "../../variables.scss";


.COC {
  button {
    &:hover {
      background-color: transparent !important;
    }
  }
  // background-color: map-get($colorPallete, themeInBetween);

  .header, h4 {
    color: map-get($colorPallete, theme);
    font-weight: bolder;
    font-size: 15px;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0;
    background-color: transparent !important;
  }

  p {
    margin-left: 15px;
  }

  font-size: 14px;

  ul li::before {
    content: "\2022";
    color: map-get($colorPallete, theme);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
  }

  ol>li {
    counter-increment: my-awesome-counter;
  }

  ol>li::before {
    content: counter(my-awesome-counter) ". ";
    color: map-get($colorPallete, theme);
    display: inline-block;
    width: 1.5em;
    margin-left: -1em;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  border-radius: 8px;
  padding: 1vw;

  .allianceairTable {
    table-layout: auto;
    width: 90%;
    border-collapse: collapse;
    margin: 15px;
  }

  .allianceairTable td,
  .allianceairTable th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .allianceairTable tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .allianceairTable tr:nth-child(odd) {
    background-color: #ffffff;
  }

  .allianceairTable tr:hover {
    background-color: #ddd;
  }

  .allianceairTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: map-get($colorPallete, theme);
    color: white;
  }

  .card-body {
    overflow-x: scroll;
  }
}