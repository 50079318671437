  .read-more__button {
    display: inline;
    background-color: transparent;
    outline: none;
    border: 0;
    padding: 0;
    margin: 0;
    font-size: 12px;
    cursor: pointer;
    color: #007f8c;
  }
  
  .read-more__button:hover {
    text-decoration: underline;
  }
  
  .read-more__text--hide {
   max-height: 0;
   font-size: 0;
   opacity: 0;
  }
  
  .read-more__text--show {
    max-height: 10em;
    opacity: 1;
    font-size: inherit;
  }
  
  .read-more__text--remaining {
    transition: opacity 240ms ease;
  }

  .clear-button {
    align-items: normal;
    background-color: rgba(0,0,0,0);
    border-color: rgb(0, 0, 238);
    border-style: none;
    box-sizing: content-box;
    color: red !important; 
    cursor: pointer;
    display: inline;
    font: inherit;
    height: auto;
    padding: 0 10px;
    perspective-origin: 0 0;
    text-align: start;
    text-decoration: underline;
    transform-origin: 0 0;
    width: auto;
    -moz-appearance: none;
    -webkit-logical-height: 1em; /* Chrome ignores auto, so we have to use this hack to set the correct height  */
    -webkit-logical-width: auto; /* Chrome ignores auto, but here for completeness */
    text-decoration: none;
  }

  .clear-button:focus {
    outline-style: none;
    outline-width: 0px;
  }

  .read-more-line {
    display: flex;    
    flex-wrap: nowrap;
  }

  .read-less {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .read-more-text {
    max-width: 100%;
  }

  .clear-button {
    width: 100px;
  }