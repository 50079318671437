@import "../../variables.scss";

.travelRoutes {
  overflow-y: scroll;
  .allianceairTable {
    table-layout: auto;
    width: 90%;
    border-collapse: collapse;
    width: 100%;
  }

  .allianceairTable td,
  .allianceairTable th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .allianceairTable tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .allianceairTable tr:nth-child(odd) {
    background-color: #ffffff;
  }

  .allianceairTable tr:hover {
    background-color: #ddd;
  }

  .allianceairTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: map-get($colorPallete, theme);
    color: white;
  }

  a {
    color: map-get($colorPallete, theme);
  }

  @media only screen and (max-width: 1024px){
    background-image: none !important;
  }
}