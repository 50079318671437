@import "../../variables.scss";

$borderRadius: 10px;

.aboutContainer {
    margin-bottom: 40px;
    align-items: stretch;
};

.aboutLeftContainer {
    background: map-get($aboutUs, containerBackground);   
    border-radius: $borderRadius;
    display: flex;
    align-items: flex-start;
    padding: 10px 20px;
};

.aboutRightContainer {
    background: map-get($aboutUs, containerBackground);   
    border-radius: 0 $borderRadius $borderRadius 0;
    display: flex;
    align-items: center;
};

.leftContainerImage {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.aboutHeaderIcon {
    height: 6vw;
    width: 6vw;
    margin-right: 10px;
    font-weight: bolder;
}

.aboutHeader {
    font-weight: bolder;
    font-size: 20px;
}

.aboutContent {
    font-size: 16px;
    margin: 15px 1.2vw;
    .row {
        margin-bottom: 20px;
    }
}
