@import "../variables.scss";

.svg svg {
  transform: scaleX(-1);
  color: map-get($header, iconColor);
  background: map-get($header, iconBackgroundColor);
  border-radius: 50%;
  margin: 0px 3px;
  padding: 2px;
  height: 18px;
  width: 18px;
}

.custom-modal
{
  height: 950px;
}

.ibe-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  // background: #B62079;
  background: #c32026;
  border-radius: 20px;
  color: white;
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
}

.float-right {
  float: right !important;
}

.ibe-navbar {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 90%;
}

.ibe-contact {
  align-items: center;
}

.ibe-contact span {
  word-wrap: break-word;
}

.text-white {
  color: white !important;
}

.bg-purple {
  background: map-get($colorPallete, theme) !important;
}

.main-header {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 204, 153, 1) 100%
  );
  // background-size: 500px;
}

.main-header .navbar {
  -webkit-transition: margin-left .3s ease-in-out;
  -o-transition: margin-left .3s ease-in-out;
  transition: margin-left .3s ease-in-out;
  margin-bottom: 0;
  border: none;
  min-height: 50px;
  border-radius: 0;
}

.main-header .navbar {
  // background-color: var(--main-gradient);
  border-bottom: map-get($header, borderBottomColor) 5px solid;
  background: map-get($header, navBackground);
  height: 60px !important;
  line-height: 23px !important;
  // padding-top: 7px !important;
}

.layout-top-nav .main-header .navbar {
  margin-left: 0;
}

.navbar-header {
  float: left;
}

li {
  list-style: none;
}

.main-header .navbar-brand {
  border-right: none !important;
  height: 65px !important;
  line-height: 23px;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}

.phone {
  transform: rotateY(180deg);
}

svg {
  margin: 0 !important;
}

.marquee-container {
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  position: relative;
  width: 100%;
}
.marquee-container:hover div {
  animation-play-state: var(--pause-on-hover);
}
.marquee-container:active div {
  animation-play-state: var(--pause-on-click);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}
.overlay::before,
.overlay::after {
  background: linear-gradient(to right, var(--gradient-color));
  content: "";
  height: 100%;
  position: absolute;
  width: var(--gradient-width);
  z-index: 2;
}
.overlay::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.overlay::before {
  left: 0;
  top: 0;
}

.marquee {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  animation-play-state: var(--play);
  animation-delay: var(--delay);
  animation-direction: var(--direction);
}
.seperator {
  margin: 0 5px;
}
.cookie-modal-btn {
  color: white !important;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 768px) {
  .top-header {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
  }
  
  .info-header {
    div,
    .bg-purple {
      text-align: left;
      margin-left: 0 !important;
      margin-right: 0;
      padding: 0;
      // margin-bottom: 1rem;
    }

    .py-5 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-top: 2rem !important;
      margin-bottom: 0 !important;
    }
  }

  .navbar-brand {
    padding: 0;
  }

  .ibe-navbar {
    padding: 0;
  }
}
