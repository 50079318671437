@import "../../variables.scss";

.career {
  .header {
    color: black !important;
    padding: 5px;
    width: 100%;
    text-align: left;
    border: none;
    margin-bottom: 20px;
    margin-top: 20px;
    .title {
      font-size: 2.0rem;
      font-weight: bolder;
      color:  map-get($colorPallete, theme) !important;
    }

    img {
      width: 70px;
      height: auto;
    }
  }

  button {
    display: flex;
    align-items: center;
  }

  .row {
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  button {
    background: map-get($colorPallete, themeInBetween) !important;
    color: black !important;
    font-size: 1.8rem;
    font-weight: bolder;
    padding: 5px;
    width: 100%;
    text-align: left;
    border: none;

    img {
      width: 70px;
      height: auto;
      margin: 10px;
    }

    &:hover {
      color: black;
    }

    &:focus {
      border: none;
      box-shadow: none;
      background: map-get($colorPallete, theme) !important;
      color: white !important;
    }

    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 8px;
  }

  

}