@import "../../variables.scss";

.search {
  background: map-get($map: $colorPallete, $key: themeContrast);
  margin: 0 !important;
  .section {
    margin-bottom: 20px;

    .header {
      font-size: 20px;
    }

    .link {
      margin-left: 15px;
      margin-top: 10px;
      font-size: 16px;

      a {
        color: map-get($map: $colorPallete, $key: theme)
      }
    }
  }

  .message {
    padding: 20px 0px;
    font-size: 22px;
    text-align: center;
  }
}