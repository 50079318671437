.accordionButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;

  svg {
    color: black;
  }

  width: 100%;
  background-color: transparent;

  &:focus,
  &:hover {
    outline: none;
  }
}