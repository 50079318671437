@import "../variables.scss";

.footer {
    width: 100%;
    height: 50px;
    background-color: map-get($copyright, background);
    color: map-get($copyright, color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
}