@import "../../variables.scss";

.faq {
    .row {
        margin: 0;
        padding: 0;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    button {
        height: 100%;
        display: flex;
        align-items: center;
    }

    button.faqInactive {
        background: map-get($colorPallete, themeInBetween) !important;
        color: black !important;
        font-size: 1.8rem;
        font-weight: bolder;
        padding: 5px;
        width: 100%;
        text-align: left;
        border:none;
        img {
            width: 70px;
            height: auto;
            margin: 10px;
        }
        &:hover
        {
            color: black;
        }
        &:focus {
            border: none;
            box-shadow: none;
            background: map-get($colorPallete, theme ) !important;
            color: white !important;
        }
        margin-bottom: 20px;
        margin-top: 20px;
        border-radius: 8px;
    }

    button.faqActive {
        border: none;
        box-shadow: none;
        background: map-get($colorPallete, theme ) !important;
        color: white !important;
        font-size: 1.8rem;
        font-weight: bolder;
        padding: 5px;
        width: 100%;
        text-align: left;
        border:none;
        img {
            width: 70px;
            height: auto;
            margin: 10px;
        }
        &:focus {
            border: none;
            box-shadow: none;
            background: map-get($colorPallete, theme ) !important;
            color: white !important;
        }
        margin-bottom: 20px;
        margin-top: 20px;
        border-radius: 8px;
    }
    
}
