.allianceairContainer {
  
}

@keyframes run {
    0% { left: 0;}
    100%{ left: 150%;}
}

.loading-screen {
    background-image: url(/Images/loading-bg.webp) !important;
    background-position: bottom !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    box-sizing: border-box;
    position: fixed;
    background: rgba(255, 255, 255, 0.8);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
}

#planeAnimation {
    bottom:15%;
    position:absolute;
    -webkit-animation-direction:normal;
    -webkit-animation:linear infinite;
    -webkit-animation-name: run;
    -webkit-animation-duration: 5s;
}

.text-center {
    text-align: center !important;
}

.position-relative { 
    position: relative !important; 
}

.outer-loading {
    height: 100vh;
}

.loading-text {
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 62.5vh;
    right: 0;
    bottom: 0;
    display: block;
}

.inner-loading {
    top: 50vh; 
}

.loadingoverlay {
    background: white !important;
    background-position: bottom !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

