@import "../../variables.scss";

.formikComponentContainer {
  svg {
    margin-right: 5px;
  }
}

.formikComponentContainer .form-control{
  &:focus {
    border: 1px solid map-get($colorPallete, theme) !important;
    box-shadow: none !important;
  }
}