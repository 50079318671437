@import "../../variables.scss";

.leadership {
     background: white;
     justify-content: space-evenly;
     align-items: stretch;
    .leaderName {
        text-align: center;
        font-weight: bold;
        font-size: medium;
    };

    .col {
        display: flex;
        flex-direction: column;
    }

    ul li::before {
        content: "\2605";
        color: map-get($colorPallete, theme);
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    
      ol {
        list-style: none;
        counter-reset: my-awesome-counter;
      }
    
      ol li {
        counter-increment: my-awesome-counter;
      }
    
      ol li::before {
        content: counter(my-awesome-counter) ". ";
        color: map-get($colorPallete, theme);
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    
      ul,
      ol {
        padding-left: 20px;
      }
 
      hr {
        border: 1px solid map-get($colorPallete, theme);
        border-radius: 5px;
      }

      .content {
          background-color: map-get($map: $colorPallete, $key: themeInBetween);
          padding: 10px;
          flex-grow: 1;
      }
}