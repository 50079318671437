@import "../../variables.scss";

.partnerLoginContainer {
    background-color: map-get($colorPallete, themeContrast);
    padding-bottom: 20px;
    button:hover {
        background: map-get($map: $colorPallete, $key: themeBackground) !important;
    }
    .row {
        margin-left: 0px !important;
    }
}

hr {
    width: 100%;       
  }

.btn-primary {
      background: map-get($colorPallete, theme) !important;
      border: none !important;
}

h4 {
    background-color: #e2e5e8;
    padding: 10px;
    border-radius: 5px; 
}

h3 {
    text-align: center;
    padding: 10px;
}

.note {
    font-size: 12px;
    color: map-get($map: $colorPallete, $key: theme) !important;
}
