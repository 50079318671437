@import "../variables.scss";

.links{
    margin-left: 20px;
    margin-right: 20px;
    // background: map-get( $link, background ) !important;
    justify-content: space-evenly;
    .linksTitle {
        color: map-get( $link, titleColor );
        font-size: large;
    }
    a {
    color: map-get( $link, subTitleColor ) !important;
    text-decoration: none;
    display: block;
    font-size: map-get( $link, fontsize ) !important;
    padding: 0px;
    margin: 0px;
    margin-bottom: 2px;
    }
    p {
        font-weight: bolder;
        padding: 0px;
        margin: 0px;
        margin-bottom: 2px;
    }
}

.social-media {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 15px;
    // background: map-get( $link, background ) !important;
    p {
        font-size: large;
        color: map-get( $link, titleColor );
        font-weight: bolder;
        margin: 0;
        padding: 0;
    }
}

.above-footer {
    padding: 20px 20px 0 20px;

    div {
        padding-bottom: 10px;
    }
    p {
        font-weight: bold;
        margin: 0;

    }
    a, a:hover {
        text-decoration: none;
        color: black;
    }
}

.below-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: map-get($link , secondayBackground);

    a, a:hover {
        text-decoration: none;
        color: map-get($link , subTitleColor);
        font-weight: bold;
        font-size: medium;
    }
}

.footer-bg {
    background: map-get( $link, background ) !important;
}

.displayInline {
    display: inline !important;
}

@media (max-width: 768px) {
    .below-footer {
        flex-direction: column;
        span {
            display: none;
        }
    }
}