@import "../../../variables.scss";

.faqitem {
  background-color: map-get($colorPallete, themeInBetween);
  overflow-y: scroll;
  strong {
    margin: 10px;
  }

  .header {
    color: map-get($colorPallete, theme);
    font-weight: bolder;
    font-size: 15px;
  }

  font-size: 14px;

  ul li::before {
    content: "\2022";
    color: map-get($colorPallete, theme);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
  }

  ol li {
    counter-increment: my-awesome-counter;
  }

  ol li::before {
    content: counter(my-awesome-counter) ".";
    color: map-get($colorPallete, theme);
    display: inline-block;

  }

  ul,
  ol {
    padding-left: 20px;
  }

  border-radius: 8px;
  padding: 10px;

  .allianceairTable {
    table-layout: auto;
    width: 90%;
    border-collapse: collapse;
    width: 100%;

    p {
      margin-left: 0px;
    }
  }

  .allianceairTable td,
  .allianceairTable th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .allianceairTable tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .allianceairTable tr:nth-child(odd) {
    background-color: #ffffff;
  }

  .allianceairTable tr:hover {
    background-color: #ddd;
  }

  .allianceairTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: map-get($colorPallete, theme);
    color: white;
  }

  .card-body {
    overflow-y: scroll;
  }
}