@import "../../variables.scss";

.travelMandate {
  font-size: medium;
    color: black;
    font-weight: normal;
    letter-spacing: normal;
  .row {
    justify-content: center;
    margin-left: 0px;
    margin-right: 0px;
    padding: 10px;
  }

  .grayBackground {
    background: map-get($colorPallete, themeInBetween);
  }

  .whiteBackground {
    background: map-get($colorPallete, themeContrast);
  }

  .header {
    color: map-get($colorPallete, theme);
    font-weight: bolder;
    font-size: large;
    border-radius: 8px;
    padding: 10px;
    letter-spacing: 0.04em;
    img {
      height: 80px;
      width: 80px;
    }  
  }

  .oval {
    border-radius: 8px;
  }

  .pinkBorder {
    border: solid;
    border-width: thin;
    border-color: map-get($colorPallete, theme);
    padding: 10px
  }

  .grayBorder {
    border: solid;
    border-color: lightgray;
    border-width: thin;
  }

  .headerText {
    font-size: large;
    color: map-get($colorPallete, theme);
    font-weight: bold;
    font-stretch: expanded;
    letter-spacing: 0.04em;
  }

  .titleText {
    font-size: normal;
    color: map-get($colorPallete, theme);
    font-weight: bold;
  }
 
  .mainBtn {
    font-size: larger;
    color: white !important;
    background: map-get($colorPallete, theme) !important;
    font-variant-caps: all-petite-caps;
    font-weight: bold;
    border-radius: 4px;
  }

  .cardContent {
    background-color: map-get($colorPallete, themeInBetween);
    font-size: normal;
    color: black;
    font-weight: normal;
    letter-spacing: normal;
  }

  .faqText {
    position: absolute;
    top: 20px;
    left: 15px;
    font-size: x-large;
    font-weight: bold;
    color: black;
  }

  .pushDown {
    margin-bottom: 20px;
  }
}