$maxWidth: 1200px;

$fontsize: (xs: 12px,
    sm: 12px,
    md: 14px,
    lg: 14px,
    xl: 14px,
);

$margin: (leftGlobal: auto,
    rightGlobal: auto,
);

$breakpoints: (xs: 0px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
);

$colorPallete: (
    theme: var(--aaal-primary),
    themeSecondary: var(--aaml-secondary),
    themeBackground : var(--aaal-background),
    themeNavbarBackground : var(--aaal-navbar-background),
    themeContrast : white,
    themeInBetween: #e2e5e8,
    themeFont: var(--aaml-secondary),
    themeContrastFont: black,
    themeInBetweenFont: black,
);

$fontPallete: (small: 14px,
    medium: 14px,
    big: 14px,
    xbig: 14px,
);

$socialtray: (background: map-get($colorPallete, themeFont),
);

$header: (fontSize: map-get($fontPallete, medium),
    navBackground: map-get($colorPallete, themeNavbarBackground),
    navFontColor: map-get($colorPallete, theme),
    navHoverBackground: map-get($colorPallete, themeContrast),
    navHoverFontColor: map-get($colorPallete, themeContrastFont),
    loginBackground: map-get($colorPallete, themeBackground),
    loginColor: map-get($colorPallete, themeContrast),
    iconColor: map-get($colorPallete, themeContrast),
    iconBackgroundColor: map-get($colorPallete, themeBackground),
    borderBottomColor: map-get($colorPallete, theme),
);

$link: (background: map-get($colorPallete, themeNavbarBackground),
    secondayBackground: map-get($colorPallete, themeBackground),
    titleColor: map-get($colorPallete, theme),
    subTitleColor: map-get($colorPallete, themeSecondary),
    fontsize: map-get($fontPallete, medium),
);

$copyright: (background: map-get($colorPallete, themeBackground),
    color: map-get($colorPallete, themeFont),
    fontsize: map-get($fontPallete, medium),
);

$contact: (background: map-get($colorPallete, themeInBetween),
    bodyFontSize: map-get($fontPallete, medium),
    headerFontSize: map-get($fontPallete, big),
);

$aboutUs: (containerBackground: map-get($colorPallete, themeInBetween),
);

$book: (formBackground: map-get($colorPallete, themeContrast),
    borderBottom: map-get($colorPallete, theme),
);

.pinkColor {
    color: map-get($map: $colorPallete, $key: theme)
}

#consent-popup {
    position: fixed;
    bottom: 35;
    left: 0;
    right: 0;
    padding: 2rem 8rem;
    background-color: #fff;
    transition: opacity 10s ease;

    &.hidden {
        opacity: 0;
    }
}
.mybtn{
    font-size: 16px;
}

.containerme .mybtn{
    position: absolute;
    top: 90%;
    left: 50%;
    // right: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    padding: 5px 5px;
    text-align: center;
    border-radius: 5px;
    border: none;
}

.mybtn{
    background: map-get($colorPallete, themeNavbarBackground);
    width: 150px;
}

.mybtn:hover{
    background-position: right;
}