@import "../../variables.scss";

.specialAssistance {

  .row {
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .headerButton {
    background: map-get($colorPallete, themeInBetween) !important;
    color: black;
    font-size: 2.2rem;
    font-weight: bolder;
    padding: 5px;
    width: 100%;
    text-align: left;
    border: none;

    img {
      width: 70px;
      height: auto;
      margin: 0px 18px 0px 5px;
    }
    div {
      display: inline;
    }
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 8px;
  }

  .baner {
    background: white !important;
    border-radius: 8px;
    padding: 20px 0px;
    border: map-get($colorPallete, theme) solid 1px;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;

    .bannerLeft {
      .bannerHeader {
        img {
          width: 70px;
          height: 70px;
        }
        justify-content: center;
        text-align: center;
        font-size: 1.9rem;
        color: map-get($colorPallete, theme);
        font-weight: bolder;
        margin-bottom: 10px;
        div {
          display: inline;
          margin-left: 10px;
        }
      }

      .bannerText {
        font-size: 1.5rem;
      }
    }

    .bannerButton {
      margin-top: 40px;
      font-size: 1.5rem;
      font-weight: 600;
      padding: 10px;
      border-radius: 5px;
      background: map-get($colorPallete, theme) !important;

      &:focus {
        box-shadow: none;
      }
    }

    .bannerRight {
      img {
        width: 100%;
        height: 250px;
        border: gray solid 1px;
        border-radius: 8px;
      }
    }
  }

  .bannerButton {
    margin-top: 40px;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 10px;
    border-radius: 5px;
    background: map-get($colorPallete, theme) !important;

    &:focus {
      box-shadow: none;
    }
  }

  .buttonArray {
    justify-content: flex-start;

    button {
      width: 100%;
      background: map-get($colorPallete, themeInBetween) !important;
      color: black !important;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 20px;

      &:focus {
        box-shadow: none;
        background: map-get($colorPallete, theme) !important;
        color: white !important;
      }
    }
  }

  .okButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px;

    button {
      padding: 0px 20px;
    }
  }

  button {
    &:focus {
      box-shadow: none;
    }
  }

  button {
    &:hover {
      background-color: transparent !important;
    }
  }

  background-color: map-get($colorPallete, themeInBetween);

  .header,
  h4 {
    color: map-get($colorPallete, theme);
    font-weight: bolder;
    font-size: 15px;
    margin: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0;
    background-color: transparent !important;
  }

  p {
    margin-left: 15px;
  }

  font-size: 14px;

  ul li::before {
    content: "\2022";
    color: map-get($colorPallete, theme);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
  }

  ol>li {
    counter-increment: my-awesome-counter;
  }

  ol>li::before {
    content: counter(my-awesome-counter) ". ";
    color: map-get($colorPallete, theme);
    display: inline-block;
    width: 1.5em;
    margin-left: -1em;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  border-radius: 8px;
  padding: 15px 0px;

  .allianceairTable {
    table-layout: auto;
    width: 90%;
    border-collapse: collapse;
    margin: 15px;
  }

  .allianceairTable td,
  .allianceairTable th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .allianceairTable tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .allianceairTable tr:nth-child(odd) {
    background-color: #ffffff;
  }

  .allianceairTable tr:hover {
    background-color: #ddd;
  }

  .allianceairTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: map-get($colorPallete, theme);
    color: white;
  }

  @media only screen and (max-width: 525px ){
    .headerButton {
      div {
        display: block;
        text-align: center;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    };
    .bannerHeader {
      div {
        display: block;
        text-align: center;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}