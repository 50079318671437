@import "../../variables.scss";

.group-booking {
  .row {
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  background-color: map-get($colorPallete, themeInBetween);
  font-size: 16px;
  border-radius: 8px;
  padding: 10px;
}