.mc-container {
  width: 90%;
  margin: auto;
}

// .mc-slider {
// }

.mc-item {
  margin: auto;
  img {
    display: block;
    margin: auto;
    cursor: pointer;
  }
}

// .mc-dotlist {
// }
.custom-right-arrow {
  position: absolute !important;
  right: 4px;
  z-index: 1;
  border: 1px solid #ffcc99;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 6px;
  opacity: 0.8;
  cursor: pointer;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.custom-right-arrow:hover {
  opacity: 1;
}
.custom-left-arrow {
  position: absolute !important;
  left: 4px;
  z-index: 1;
  border: 1px solid #ffcc99;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 6px;
  opacity: 0.8;
  cursor: pointer;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.custom-left-arrow {
  opacity: 1;
}

@media only screen and (max-width: 425px){
  .mc-item {
    img {
      width: 80%;
    }
  }
}