@import "../../variables.scss";
@media only screen and (min-width: 769px){

.bookContainer > .row {
  margin-left: 0px;
  margin-right: 0px; 
}

.bookOverlayControls {
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  .form-label {
    margin-right: 5px !important;
  }
  
};

.bookForm {
  // background: map-get($book, formBackground);
  // padding: 10px;
  // border-radius: 20px;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
};

.bookBottomRibbon {
  img {
    height: 18px;
    width: 100%;
  }
}

.bookFlightOverlay {
  position: absolute;
  bottom: 40px;
  right: 12vw;
}

.bookOverlayControlContainer {
  svg {
    margin-right: 5px;
  }
}

.bookNotes
{
  background-color: map-get($colorPallete, themeInBetween);
  padding: 10px 20px;
  border-radius: 7px;
  justify-content: center;
  font-size: 16px;
}
.bookframe{
  width:100%;
  height:585px;
  position: absolute;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.bookValues {
  background-color: map-get($colorPallete, theme);
  color: map-get($colorPallete, themeFont);
  border-radius: 8px;
  .row {
    justify-content: center;
    font-weight: bold;
    text-align: center;
  }
  svg {
    background-color: white;
  }
}
    .bookOverlayControlContainer {
      z-index: 4;
      position: absolute;
      top: 25px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 88%;
      max-height: 312px;
      margin: 0 auto;
    };
    .bookContainer {
    position: relative;
};

  }

.carousel-control-prev {
  justify-content: start;
  overflow: hidden;
}
.carousel-control-next {
  justify-content: end;
  overflow: hidden;
}
@media only screen and (max-width: 425px){
  bookOverlayControlContainer {
    width: 100%;
    position: relative;
    form{
      padding: 0;
      justify-content: center;
  }
}
  .offset-1 {
    padding: 0;
  }
  .col-11 {
    margin: 0;
  }
  .bookframe{
    height: 760px;
    max-height: 100%;
    min-height: 100%;
    width: 100%;
    position: relative;
  }
  
  .bookOverlayCarousel{
    display: none !important;
  }
  .bookOverlayControlContainer{
    position:relative;
    
  }
}
