@import "./variables.scss";

// @font-face {
//   font-family: 'Avenir!important;';
//   src: local('Avenir'), url(./fonts/Avenir.ttc) format('truetype');
// };

// @font-face {
//   font-family: 'Avenir-Medium!important;';
//   src: local('Avenir-Medium'), url(./fonts/Avenir-Medium.ttf) format('truetype');
// };

html, head{
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'Calibri', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background: map-get($colorPallete, themeBackground);
};


